<template>
  <div class="pt-20 pb-14">
    <app-title text="منتجاتنا" />

    <div class="container my-14">
      <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
        <product-card
          v-for="product in products"
          :key="product?.id"
          :product="product"
        />
      </div>

      <!-- <button
        class="btn mx-auto block mt-14 bg-transparent border-yellow-400 text-yellow-400 text-lg rounded-none px-10 hover:bg-yellow-400 hover:text-white"
      >
        جميع المنتجات
      </button> -->
    </div>
  </div>
</template>
<script lang="ts" setup>
const {products} = useProducts();
</script>
