<template>
  <div class="pb-14 pt-4">
    <app-title text="معرض الصور" />
    <div class="container">
      <div class="grid md:grid-cols-3 gap-6 mt-14">
        <div
          v-for="item in gallery"
          :key="item?.id"
          class="rounded-xl overflow-hidden"
        >
          <app-image :src="item?.image" alt="gallery" fit="cover" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const {gallery} = useGallery();
</script>
