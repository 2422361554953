<template>
  <div id="branches" class="py-20 mb-20 bg-yellow-300/20">
    <app-title text="نقاط البيع" />

    <div
      class="container flex flex-col lg:flex-row gap-6 bg-white mt-12 lg:p-0"
    >
      <div class="lg:w-1/3 py-10 lg:ps-6">
        <h4 class="md:text-lg text-gray-600">إبحث عن أقرب نقطة بيع لك</h4>
        <search-bar class="mt-6" @change="onSearchChange" />

        <div class="relative mt-6">
          <div
            v-if="loading"
            class="absolute top-0 start-0 w-full h-full bg-yellow-100/50 rounded-lg flex justify-center pt-2"
          >
            <Spinner />
          </div>
          <div
            v-if="locations?.length"
            class="map-locations flex flex-col gap-y-3"
          >
            <location-card
              v-for="item in locations"
              :key="item?.id"
              :location="item"
              :class="item?.id === location?.id ? 'border-yellow-400' : ''"
              @click="onChangeLocation(item)"
            />
          </div>
          <p class="mt-6 text-yellow-400" v-else>لا يوجد نتائج ...</p>
        </div>
      </div>
      <div class="map-view lg:w-2/3 relative">
        <div
          class="absolute top-0 start-0 w-full h-full flex items-center justify-center"
        >
          <location-modal v-if="location" :location="location" />
          <p
            class="py-2 px-4 rounded-lg bg-white text-yellow-400 md:text-lg border border-yellow-400"
            v-else
          >
            لا يوجد نتائج ..
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const searchQuery = ref("");
const location = ref<LocationType | null>(null);
const {locations, loading} = useLocations(searchQuery);

//On search change
const onSearchChange = ({value}: Record<string, string>) => {
  searchQuery.value = value;
};

watch(
  () => locations?.value,
  (value) => {
    if (!value?.length) {
      location.value = null;
      return;
    }
    location.value = value[0];
  },
  {
    immediate: true,
  }
);

//On change location action
const onChangeLocation = (value: LocationType) => {
  location.value = value;
};
</script>

<style lang="postcss" scoped>
.map {
  &-view {
    background: url("/images/map.png") no-repeat center center;
    background-size: cover;
    height: 740px;
  }
  &-locations {
    max-height: 520px;
    overflow-y: auto;
  }
}
</style>
