export type LocationType = {
  id: number;
  address: string;
  description: string;
  image: string;
  lat: number;
  lng: number;
  phone: string;
  title: string;
  schedule: Record<string, Record<string, string>>;
};

export const useLocations = (search: MayBeRef<String> = "") => {
  const {data, pending, execute} = useAPI("/pos", {
    key: "locations",
    query: {
      search_query: search,
    },
    watch: [search],
    server: false,
  });

  const locations = computed(() => {
    return (data.value?.data as unknown as LocationType[]) || [];
  });

  const paginator = computed(() => {
    return data.value?.paginator;
  });

  return {
    locations,
    paginator,
    loading: pending,
    execute,
  };
};
