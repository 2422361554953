<template>
  <div class="rounded-lg bg-white py-4 w-80" v-bind="$attrs">
    <div class="rounded-lg overflow-hidden mx-4">
      <app-image
        :src="location?.image"
        alt="map location"
        ratio="4-2"
        fit="fill"
      />
    </div>

    <div class="flex items-center justify-between mt-4 px-4">
      <h3 class="md:text-lg">{{ location?.title }}</h3>
      <!-- <span class="rounded-full py-1 px-2 bg-green-400 text-white">2.3 كم</span> -->
    </div>

    <p class="flex items-center gap-x-3 mt-4 px-4">
      <svg-icon name="location" class="fill-yellow-400 w-4 h-4" />
      {{ location?.address }}
    </p>

    <p
      class="px-4 flex items-center gap-x-3 mt-4 border-y border-gray-100/20 py-3"
    >
      <svg-icon name="phone" class="fill-yellow-400 w-4 h-4" />
      {{ location?.phone }}
    </p>

    <p class="flex items-center gap-x-3 mt-4 px-4">
      <svg-icon name="timer" class="fill-yellow-400 w-6 h-6" />
      مواعيد العمل
    </p>

    <div class="flex flex-col gap-y-2 mt-4 px-4">
      <p
        v-for="(day, index) in location?.schedule"
        :key="index"
        class="flex items-center gap-2 flex-wrap text-sm"
      >
        <span class="w-12 text-gray-400">{{ switchDays[index] }}</span>
        {{ day?.from }} {{ day?.to }}
      </p>
    </div>

    <div class="px-4 border-t border-gray-100/20 mt-4 pt-4">
      <a
        :href="directedUrl"
        target="_blank"
        class="inline-block text-sm py-2 px-4 rounded-full border border-yellow-400 text-yellow-400"
        >عرض علي الخريطة</a
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {LocationType} from "~/composables/locations.js";

type Props = {
  location: LocationType;
};

const {location} = defineProps<Props>();

const switchDays = ref<Record<string, string>>({
  friday: "الجمعة",
  monday: "الاثنين",
  saturday: "السبت",
  sunday: "الأحد",
  thursday: "الخميس",
  tuesday: "الثلاثاء",
  wednesday: "الأربعاء",
});

const directedUrl = computed(() => {
  return `https://www.google.com/maps?q=${location.lat},${location?.lng}`;
});
</script>
