type ProductType = {
  id: number;
  name: string;
  image: string;
};

export const useProducts = () => {
  const {data, pending, execute} = useAPI("/products", {
    key: "products",
    server: false,
  });

  const products = computed(() => {
    return (data.value?.data as unknown as ProductType[]) || [];
  });

  const paginator = computed(() => {
    return data.value?.paginator;
  });

  return {
    products,
    paginator,
    loading: pending,
    execute,
  };
};
