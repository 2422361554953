<template>
  <home-hero />
  <!-- <home-partner /> -->
  <home-about-us />
  <home-products />
  <home-pos />
  <home-gallery />
</template>

<script lang="ts" setup></script>
