<template>
  <div class="mb-14 mt-20">
    <app-title text="من نحن" />

    <div class="relative bg-yellow-300/20 py-10 lg:py-14 my-14">
      <div class="container my-14">
        <div class="flex flex-col lg:flex-row items-center gap-8">
          <div class="relative w-96 mx-auto max-w-full shrink-0">
            <app-image
              :src="value?.image"
              alt="about image"
              class="relative z-[2]"
            />
            <img
              src="/images/texture-f-right.svg"
              alt="texture right flower"
              class="absolute -top-6 -start-1/3 z-[1]"
            />
          </div>

          <div class="w-full">
            <div class="md:w-5/6">
              <h3 class="text-lg md:text-xl">
                <span class="text-yellow-400 font-bold">{{
                  value?.title
                }}</span>
              </h3>
              <p class="text-lg md:text-xl text-gray-400 mt-4">
                {{ value?.description }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <img
        src="/images/texture-f-left.svg"
        alt="texture left flower"
        class="absolute bottom-0 lg:-top-6 end-0"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
const {value} = useStatic("about");
</script>
