<template>
  <div class="relative hero pt-40 overflow-hidden">
    <div class="relative flex flex-col items-center gap-y-8 z-[2]">
      <img src="/images/logo.png" alt="logo" class="w-48" />
      <h3 class="text-2xl md:text-4xl text-gray-600 mt-8">
        {{ value?.title }}
      </h3>

      <div class="flex flex-col gap-y-4">
        <a
          href="#branches"
          class="group btn font-bold rounded-2xl w-58 py-4 px-10 md:text-lg flex items-center justify-center gap-x-3 hover:text-white"
        >
          جميع نقاط البيع
          <svg-icon
            name="arrow-left"
            class="fill-current w-6 h-3 group-hover:-translate-x-2 transition-all duration-150"
          />
        </a>
        <a
          href="https://nature.sa/"
          target="_blank"
          class="btn font-bold rounded-2xl w-58 py-4 px-10 md:text-lg flex items-center justify-center gap-x-3 bg-transparent text-yellow-400 border-yellow-400"
        >
          الانتقال الى المتجر
        </a>
      </div>
    </div>

    <img
      :src="value?.image"
      class="relative z-[2] mx-auto w-96 max-w-full mt-24"
      alt="hero image"
    />

    <img
      src="/images/hero-texture.svg"
      alt="texture"
      class="absolute bottom-0 start-1/2 translate-x-1/2 h-1/2 w-full z-[1] max-w-full object-cover md:object-fill"
    />
  </div>
</template>

<style lang="postcss" scoped>
.hero {
  background: url("/images/hero-bg.webp") no-repeat center center;
  background-size: cover;
}
</style>

<script lang="ts" setup>
const {value} = useStatic("hero");
</script>
