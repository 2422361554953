<template>
  <div class="spinner"></div>
</template>

<style lang="postcss" scoped>
.spinner {
  @apply w-6 h-6 rounded-full border-2 border-gray-100/50 border-b-yellow-400;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
