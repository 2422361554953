<template>
  <div class="relative">
    <input
      type="text"
      class="h-14 w-full border rounded-lg border-gray-400 pe-14 ps-6 focus:border-yellow-400 outline-0"
      :placeholder="placeholder"
      @input="onChangeAction"
    />
    <svg-icon
      name="search"
      class="fill-gray-300/20 w-5 h-5 absolute top-1/2 -translate-y-1/2 end-4"
    />
  </div>
</template>

<script lang="ts" setup>
import {debounce} from "lodash-es";

defineProps({
  placeholder: {
    type: String,
    default: "اكتب اسم الفرع",
  },
});

const emits = defineEmits(["change"]);

// On input change action
const onChangeAction = debounce((e) => {
  emits("change", {value: e.target.value});
}, 200);
</script>
