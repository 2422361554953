type GalleryType = {
  id: number;
  image: string;
};

export const useGallery = () => {
  const {data, pending, execute} = useAPI("/gallery", {
    key: "gallery",
    server: false,
  });

  const gallery = computed(() => {
    return (data.value?.data as unknown as GalleryType[]) || [];
  });

  const paginator = computed(() => {
    return data.value?.paginator;
  });

  return {
    gallery,
    paginator,
    loading: pending,
    execute,
  };
};
